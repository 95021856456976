import { Controller } from "@hotwired/stimulus"
import numbro from "numbro"

export default class extends Controller {
  static targets = ["feeTypeId", "feeTypeDescription"]

  initialize() {
    this.feeTypeDisplayFormats = JSON.parse(this.data.get("fee-type-display-formats"))
    this.feeTypeDescriptions = JSON.parse(this.data.get("fee-type-descriptions"))
  }

  feeTypeIdChanged(e) {
    const displayFormat = this.feeTypeDisplayFormats[this.feeTypeIdTarget.value]
    $(this.element).find(".input-group-text").text(displayFormat.unit_label)

    const description = this.feeTypeDescriptions[this.feeTypeIdTarget.value]
    this.feeTypeDescriptionTarget.value = description
  }
}
