import { Controller } from "@hotwired/stimulus"
import * as Tus from "tus-js-client"

export default class extends Controller {
  static targets = ["fileInput", "progressBar", "status"]

  connect() {
    this.prepareForUploadPath = this.data.get("prepareForUploadPath")
    this.afterUploadUrl = this.data.get("afterUploadUrl")
  }

  submit(e) {
    e.preventDefault()
    if (this.uploadInProgress) {
      return
    }

    const file = this.fileInputTarget.files[0]
    if (file) {
      this.prepareForUpload(file)
      this.uploadInProgress = true
    }
  }

  async prepareForUpload(file) {
    this.statusTarget.textContent = "Preparing upload..."
    const httpResponse = await fetch(this.prepareForUploadPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      credentials: 'same-origin',
      body: JSON.stringify({ "size": file.size })
    })

    const jsonResponse = await httpResponse.json()
    const uploadUrl = jsonResponse.upload_link
    this.statusTarget.textContent = "Upload in progress... Please wait."

    let upload = new Tus.Upload(file, {
      uploadUrl: uploadUrl,
      onProgress: (bytesUploaded, bytesTotal) => {
        this.progressBarTarget.value = bytesUploaded / bytesTotal
      },
      onError: (error) => {
        this.statusTarget.textContent = "Upload failed: " + error
      },
      onSuccess: () => {
        this.statusTarget.textContent = "Upload complete!"
        window.location.href = this.afterUploadUrl
      }
    })

    upload.start()
  }
}
