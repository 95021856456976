import { Controller } from "@hotwired/stimulus"
import Player from '@vimeo/player'

export default class extends Controller {
  initialize() {
    this.playerEmbedUrl = this.data.get("playerEmbedUrl")

    this.player = new Player(this.element, {
      id: this.playerEmbedUrl,
      responsive: 1
    })
  }
}
