import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.checkSelector = this.data.get("checkSelector")
  }

  checkAll(e) {
    e.preventDefault()
    $(this.checkSelector).prop('checked', true)
  }

  uncheckAll(e) {
    e.preventDefault()
    $(this.checkSelector).prop('checked', false)
  }
}
