import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["headTabLink", "bodyTabLink", "editor", "headSource", "bodySource", "livePreview"]

  initialize() {
    this.livePreviewPath = this.data.get("livePreviewPath")

    this.editor = ace.edit(this.editorTarget);
    this.editor.setOptions({
      wrap: true,
      theme: "ace/theme/chrome",
      fontSize: 16,
    })

    this.headEditSession = new ace.EditSession(this.headSourceTarget.value, "ace/mode/html")
    this.headEditSession.on('change', this.headSourceChanged.bind(this))

    this.bodyEditSession = new ace.EditSession(this.bodySourceTarget.value, "ace/mode/html")
    this.bodyEditSession.on('change', this.bodySourceChanged.bind(this))

    this.editor.setSession(this.bodyEditSession)

    this.stalePreview = true
    this.refreshPreview()
    setInterval(this.refreshPreview.bind(this), 2000)
  }

  showHead(e) {
    e.preventDefault()
    this.bodyTabLinkTarget.classList.remove("active")
    this.headTabLinkTarget.classList.add("active")
    this.editor.setSession(this.headEditSession)
    this.editor.focus()
  }

  showBody(e) {
    e.preventDefault()
    this.headTabLinkTarget.classList.remove("active")
    this.bodyTabLinkTarget.classList.add("active")
    this.editor.setSession(this.bodyEditSession)
    this.editor.focus()
  }

  headSourceChanged() {
    this.stalePreview = true
    this.headSourceTarget.value = this.editor.getValue()
  }

  bodySourceChanged() {
    this.stalePreview = true
    this.bodySourceTarget.value = this.editor.getValue()
  }

  refreshPreview() {
    if (!this.stalePreview) {
      return
    }
    this.stalePreview = false

    let csrfTokenElement = document.querySelector('meta[name="csrf-token"]')
    let csrfToken;
    if(csrfTokenElement) {
      csrfToken = csrfTokenElement.content
    }

    fetch(this.livePreviewPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: 'same-origin',
      body: JSON.stringify({
        head_source: this.headSourceTarget.value,
        body_source: this.bodySourceTarget.value
      })
    }).then(response => {
      return response.json()
    }).then(json => {
      while (this.livePreviewTarget.firstChild) {
        this.livePreviewTarget.removeChild(this.livePreviewTarget.firstChild)
      }

      let iframe = document.createElement("iframe")
      iframe.style.cssText = "width: 100%; height: 1000px; border: 1px solid gray"
      iframe.src = json.url

      this.livePreviewTarget.appendChild(iframe)
    }).catch(error => {
      console.log("Failed to get live preview: ", error.message)
    });
  }
}
