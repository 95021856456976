import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.containerSelector = this.data.get("containerSelector")
    this.className = this.data.get("className")
  }

  toggle(e) {
    e.preventDefault()
    document.querySelector(this.containerSelector).classList.toggle(this.className)
  }
}
