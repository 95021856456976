import { Controller } from "@hotwired/stimulus"
import numbro from "numbro"

export default class extends Controller {
  static targets = [ "merchantTypeId", "totalVolume", "numTransactions", "avgTicket", "totalFees" ]

  totalVolumeChanged() {
    if (this.totalVolumeTarget.value) {
      this.totalVolumeTarget.value = numbro(this.totalVolumeTarget.value).format('0,0.00') || ""
    }
  }

  numTransactionsChanged() {
    if (this.numTransactionsTarget.value) {
      this.numTransactionsTarget.value = numbro(this.numTransactionsTarget.value).format('0,0') || ""
    }

    if(this.numTransactions) {
      const avgTicket = Math.round(this.totalVolume / this.numTransactions * 100) / 100
      this.avgTicketTarget.value = numbro(avgTicket).format('0,0.00') || ""
      this.avgTicketTarget.readOnly = true
    } else {
      this.avgTicketTarget.readOnly = false
    }
  }

  avgTicketChanged() {
    if (this.avgTicketTarget.value) {
      this.avgTicketTarget.value = numbro(this.avgTicketTarget.value).format('0,0.00') || ""
    }
  }

  totalFeesChanged() {
    if (this.totalFeesTarget.value) {
      this.totalFeesTarget.value = numbro(this.totalFeesTarget.value).format('0,0.00') || ""
    }
  }

  get totalVolume() {
    return numbro.unformat(this.totalVolumeTarget.value)
  }

  get numTransactions() {
    return numbro.unformat(this.numTransactionsTarget.value)
  }

  get avgTicket() {
    return numbro.unformat(this.avgTicketTarget.value)
  }

  get actualTotalFees() {
    return numbro.unformat(this.totalFeesTarget.value)
  }
}
