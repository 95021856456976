import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  checkAll(e) {
    e.preventDefault()
    $(".user-check").prop('checked', true)
  }

  uncheckAll(e) {
    e.preventDefault()
    $(".user-check").prop('checked', false)
  }
}
