import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  initialize() {
    this.reorderPath = this.data.get("reorderPath")

    this.sortable = Sortable.create(this.element, {
      handle: ".draggable-handle",
      onSort: this.onSort.bind(this)
    })
  }

  onSort(e) {
    const domIDs = Array.from(this.element.children).map((item) => {return item.id})

    fetch(this.reorderPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      credentials: 'same-origin',
      body: JSON.stringify({dom_ids: domIDs})
    })
  }


}
