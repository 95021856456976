import { Controller } from "@hotwired/stimulus"
import numbro from "numbro"

export default class extends Controller {
  static targets = [
    "vsVolume", "vsTransactions",
    "mcVolume", "mcTransactions",
    "dsVolume", "dsTransactions",
    "amexVolume", "amexTransactions",
    "pinDebitVolume", "pinDebitTransactions",
    "totalVolume", "totalTransactions"
  ]

  formatVolume(volume) {
    return numbro(volume).format("0,0.00") || "0.00"
  }

  formatTransactions(transactions) {
    return numbro(transactions).format("0,0") || "0"
  }

  volumeChanged(e) {
    e.currentTarget.value = this.formatVolume(e.currentTarget.value)
    this.recalcVolumeTotal()
  }

  recalcVolumeTotal() {
    var volumeTargets = [this.vsVolumeTarget, this.mcVolumeTarget, this.dsVolumeTarget, this.amexVolumeTarget, this.pinDebitVolumeTarget]
    var sum = 0
    volumeTargets.forEach((target) => sum += numbro.unformat(target.value) || 0)
    this.totalVolumeTarget.value = this.formatVolume(sum)
  }

  transactionsChanged(e) {
    e.currentTarget.value = this.formatTransactions(e.currentTarget.value)
    this.recalcTransactionsTotal()
  }

  recalcTransactionsTotal() {
    var transactionsTargets = [this.vsTransactionsTarget, this.mcTransactionsTarget, this.dsTransactionsTarget, this.amexTransactionsTarget, this.pinDebitTransactionsTarget]
    var sum = 0
    transactionsTargets.forEach((target) => sum += numbro.unformat(target.value) || 0)
    this.totalTransactionsTarget.value = this.formatTransactions(sum)
  }
}
