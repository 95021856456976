import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "typePage",
    "activityPage",
      "totalVolume",
      "avgTicket",
      "totalFees",
    "contactPage",
      "businessName",
      "contactName",
      "phone",
      "email",
      "yearsInBusiness",
      "notes",
    "hiddenForm",
      "hiddenFormMerchantTypeId",
      "hiddenFormTotalVolume",
      "hiddenFormAvgTicket",
      "hiddenFormTotalFees",
      "hiddenFormBusinessName",
      "hiddenFormContactName",
      "hiddenFormPhone",
      "hiddenFormEmail",
      "hiddenFormYearsInBusiness",
      "hiddenFormNotes"
   ]

  connect() {
    if (this.hiddenFormMerchantTypeIdTarget.value === "") {
      this.showTypePage()
    } else {
      this.showActivityPage()
    }
  }

  showTypePage() {
    this.typePageTarget.classList.remove("d-none")
    this.activityPageTarget.classList.add("d-none")
    this.contactPageTarget.classList.add("d-none")
  }

  showActivityPage() {
    this.typePageTarget.classList.add("d-none")
    this.activityPageTarget.classList.remove("d-none")
    this.contactPageTarget.classList.add("d-none")
    this.totalVolumeTarget.focus()
  }

  showContactPage() {
    this.typePageTarget.classList.add("d-none")
    this.activityPageTarget.classList.add("d-none")
    this.contactPageTarget.classList.remove("d-none")

    const firstVisibleField = [
      this.businessNameTarget,
      this.contactNameTarget,
      this.phoneTarget,
      this.emailTarget,
      this.yearsInBusinessTarget,
      this.notesTarget
    ].find(function(el) {
      return window.getComputedStyle(el).getPropertyValue("display") !== "none"
    })

    if (firstVisibleField) {
      firstVisibleField.focus()
    }
  }

  chooseMerchantType(e) {
    e.preventDefault()

    this.hiddenFormMerchantTypeIdTarget.value = e.target.getAttribute("data-merchant-type-id")
    this.avgTicketTarget.value = e.target.getAttribute("data-avg-ticket")

    this.showActivityPage()
  }

  submitActivity(e) {
    e.preventDefault()

    this.hiddenFormTotalVolumeTarget.value = this.totalVolumeTarget.value
    this.hiddenFormAvgTicketTarget.value = this.avgTicketTarget.value
    this.hiddenFormTotalFeesTarget.value = this.totalFeesTarget.value

    this.showContactPage()
  }

   submitContact(e) {
    e.preventDefault()

    this.hiddenFormBusinessNameTarget.value = this.businessNameTarget.value
    this.hiddenFormContactNameTarget.value = this.contactNameTarget.value
    this.hiddenFormPhoneTarget.value = this.phoneTarget.value
    this.hiddenFormEmailTarget.value = this.emailTarget.value
    this.hiddenFormYearsInBusinessTarget.value = this.yearsInBusinessTarget.value
    this.hiddenFormNotesTarget.value = this.notesTarget.value

    // Horrorific hack here... So somehow this Stimulus controller is getting attached twice. That means it is double submitting the form.
    // Was unable to figure out how or why so this hack "fixes" it. Will probably replace Stimulus sometime in the future so not going to
    // waste more time on this.
    if (!window.alreadySubmitted) {
      window.alreadySubmitted = true
      this.hiddenFormTarget.submit()
    }

  }
}
