import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["searchbox", "nomatches"]

  initialize() {
    this.searchables = this.data.get("searchables")
  }

  simpleSearch() {
    var cardText;
    var queryWords = this.searchboxTarget.value.toLowerCase().split(' ');

    for (var i=0; i < $(this.searchables).length; i++) {
      var cardWords = $(this.searchables)[i].innerText.trim().toLowerCase().split(' ');

      if ( queryWords.every(function(qw) { return cardWords.some(function(cw) { return cw.includes(qw) }) } )) {
        $(this.nomatchesTarget).addClass('d-none');
        $(this.searchables)[i].classList.remove("d-none");
      } else {
        $(this.searchables)[i].classList.add("d-none");
        if ($(this.searchables).not('.d-none').length == 0) {$(this.nomatchesTarget).removeClass('d-none');}
      }
    }
  }

}
