import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton", "otherRadioButton", "otherTextarea"]

  select(e) {
    if (e.target === this.otherRadioButtonTarget) {
      this.otherTextareaTarget.classList.remove("d-none")
      this.otherTextareaTarget.disabled = false
    } else {
      this.otherTextareaTarget.classList.add("d-none")
      this.otherTextareaTarget.disabled = true
    }
    if (this.submitButtonTarget.disabled) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove("disabled")
    }
  }

  updateOther(e) {
    if (this.otherRadioButtonTarget.checked) {
      this.otherRadioButtonTarget.value = e.target.value || "Other"
    }
  }
}
