import { Controller } from "@hotwired/stimulus"
import Player from '@vimeo/player'

export default class extends Controller {
  initialize() {
    this.playerEmbedUrl = this.data.get("playerEmbedUrl")
    this.startWatchingPath = this.data.get("startWatchingPath")
    this.updateProgressPath = this.data.get("updateProgressPath")
    this.lastEventWallClockInstant = new Date()
    this.lastEventPlayerSeconds = this.data.get("startAt")
    this.lastUpdateSentWallClockInstant = new Date()

    this.player = new Player(this.element, {
      id: this.playerEmbedUrl,
      responsive: 1
    })

    this.player.setCurrentTime(this.lastEventPlayerSeconds)

    this.player.on('play', (e) => {
      // Need to check for existence of e because play event can be triggered
      // on seek without an argument. This seems to be a bug in the Vimeo
      // player.
      if (e) {
        this.lastEventWallClockInstant = new Date()
        this.lastEventPlayerSeconds = e.seconds
        this.sendStartWatching(e.seconds, e.duration)
      }
    })

    this.player.on('pause', (e) => {
      this.lastEventWallClockInstant = new Date()
      this.lastEventPlayerSeconds = e.seconds
      this.sendUpdateProgress(e.seconds)
      this.videoLessonViewID = undefined
    });

    this.player.on('seeked', (e) => {
      if (this.videoLessonViewID) {
        // record final position before seek
        this.sendUpdateProgress(this.lastEventPlayerSeconds)
        this.videoLessonViewID = undefined
      }

      this.lastEventWallClockInstant = new Date()
      this.lastEventPlayerSeconds = e.seconds
      this.sendStartWatching(e.seconds, e.duration)
    });

    this.player.on('timeupdate', (e) => {
      const now = new Date()
      const wallTimeSinceLastEvent = (now - this.lastEventWallClockInstant) / 1000
      const playerSecondsSinceLastEvent = e.seconds - this.lastEventPlayerSeconds

      // timeupdate events at the new location can be sent before the seeked event. Detect and ignore these.
      const outOfOrderEvent = (
        playerSecondsSinceLastEvent < 0 // backwards seek occurred
        ||
        Math.abs(playerSecondsSinceLastEvent - wallTimeSinceLastEvent) > 1 // a forward seek of more than 1 second occurred
      )

      if (outOfOrderEvent) {
        // Do nothing until seeked event is handled
        return
      }

      this.lastEventWallClockInstant = now
      this.lastEventPlayerSeconds = e.seconds

      // Rate limit updates to every 10 seconds
      if (now - this.lastUpdateSentWallClockInstant > 10000) {
        this.sendUpdateProgress(e.seconds)
      }
    });
  }

  sendStartWatching(position, duration) {
    if (this.sendStartWatchingInProgress) {
      return
    }
    this.sendStartWatchingInProgress = true

    this.lastUpdateSentWallClockInstant = new Date()

    fetch(this.startWatchingPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      credentials: 'same-origin',
      body: JSON.stringify({ position: position, duration: duration })
    }).then((response) => {
      if (response.ok) {
        return response.json()
      }
    }).then((responseJSON) => {
      this.videoLessonViewID = responseJSON.id
    }).catch((err) => {
      console.log("sendStartWatching failed", err)
    }).then(() => {
      this.sendStartWatchingInProgress = false
    })
  }

  sendUpdateProgress(position) {
    this.lastUpdateSentWallClockInstant = new Date()

    fetch(this.updateProgressPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      credentials: 'same-origin',
      body: JSON.stringify({ video_lesson_view_id: this.videoLessonViewID, position: position })
    })
  }

}
