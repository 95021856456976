import { Controller } from "@hotwired/stimulus"
import Player from '@vimeo/player'

export default class extends Controller {
  static targets = ["wrapper"]

  initialize() {
      var lessonTracking = this.data.get('lessonTracking');
      var vimeoId = this.data.get("id");

      // create player
      var player = new Player($(this.wrapperTarget), {
          id: vimeoId,
          responsive: 1
      });

      if (player && lessonTracking == "true") {

        // set all variables needed to send data to database
        var startAt = this.data.get("startAt");
        var courseId = this.data.get("courseId");
        var lessonId = this.data.get("lessonId");
        var videoDuration;

        var sendUpdateData = function() {
          player.getCurrentTime().then(function(seconds) {
            $.post("/courses/" + courseId + "/lessons/" + lessonId + "/update_progress", {
              seconds_into: seconds,
              duration: videoDuration
            });
          });
        }

        // set current time
        player.setCurrentTime( startAt );

        // get duration of video
        player.getDuration().then(function(duration) {
            videoDuration = duration;
        });

        // save data in database every 10 or so seconds
        var runs = 0;
        player.on('timeupdate', function() {
          if (runs >= 40) {
            runs = 0;
            sendUpdateData();
          } else {runs++;}
        });

        // save data when user seeks or the video ends
        player.on('ended', sendUpdateData );
        player.on('seeked', sendUpdateData );
      }
  }

}
