import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["proposalIdInput"]

  copyProposalId(e) {
    e.preventDefault()
    const proposalId = e.target.getAttribute("data-proposal-id")
    this.proposalIdInputTarget.value = proposalId
  }
}
