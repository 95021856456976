import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "tcAgree"]

  submitForm(e) {
    if(!this.tcAgreeTarget.checked) {
      alert("Please agree to terms and conditions to start subscription.")
      e.preventDefault()
    }
  }
}
