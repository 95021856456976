import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["savings", "residuals", "fees"]

  initialize() {
    this.recalcPath = this.data.get("recalcPath")
  }

  formChanged(e) {
    let payload = {"fee_rates": {}}

    this.element.querySelectorAll("input[type=number]").forEach((el) => {
      const value = parseInt(el.value) || 0 // Change blank or other non-number to 0
      const min = parseInt(el.min)
      const max = parseInt(el.max)

      if (min && value < min) {
        el.value = min
      }
      if (max && value > max) {
        el.value = max
      }

      payload.fee_rates[el.dataset.feeName] = el.value
    })

    fetch(this.recalcPath, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
      },
      credentials: 'same-origin',
      body: JSON.stringify(payload)
    }).then((response) => {
      if(response.ok) {
        return response.json()
      }
    }).then((responseJSON) => {
      if (responseJSON.savings) {
        this.savingsTarget.innerHTML = responseJSON.savings
      }
      this.residualsTarget.innerHTML = responseJSON.residuals
      // this.feesTarget.innerHTML = responseJSON.fees
      Object.keys(responseJSON.fee_amounts).forEach((k) => {
        this.element.querySelector(`span.total[data-fee-name='${k}']`).innerHTML = responseJSON.fee_amounts[k]
      })
    }).catch((err) => {
      console.log("update program fees failed", err)
    })
  }
}
